<script setup lang="ts">
import type { Tip } from '~/types/commons'

interface Props {
  tipList: Tip[]
  isBreakable?: boolean
  border?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isBreakable: false,
  border: true,
})

const tipListFormatted = computed(() => {
  return props.tipList.map(({ variant, icon: tipIcon, ...tip }) => {
    let iconColor: string = 'text-pink-500', icon: ReturnType<typeof resolveComponent>

    if (variant === 'success') {
      icon = tipIcon ?? resolveComponent('YscIconsCheckCircle')
    }
    else if (variant === 'warning') {
      icon = tipIcon ?? resolveComponent('YscIconsShield')
    }
    else if (variant === 'danger') {
      icon = tipIcon ?? resolveComponent('YscIconsWarning')
      iconColor = 'text-red-500'
    }
    icon ??= resolveComponent('YscIconsInfo')

    return {
      icon,
      iconColor,
      ...tip,
    }
  })
})
</script>

<template>
  <TransitionGroup
    name="transition-tip-list"
    tag="div"
  >
    <YscCardExpendable
      v-for="tip in tipListFormatted"
      :key="tip.title"
      :border="border"
      class="break-inside-avoid-column transition-all duration-500 mb-12 last:mb-0"
      is-locked
    >
      <template #titleIcon>
        <component
          :is="tip.icon"
          class="h-6 w-6"
          :class="tip.iconColor"
        />
      </template>

      <template #title>
        {{ tip.title }}
      </template>

      <div
        class="py-2.5"
        :class="{ 'md:py-5': border }"
      >
        <i18n-t
          v-if="tip.interpolated?.text"
          :keypath="tip.description"
          :tag="tip.interpolated.isList ? 'div' : 'p'"
          scope="global"
        >
          <template #[tip.interpolated.key]>
            <NuxtLink
              v-if="tip.interpolated.link"
              :to="tip.interpolated.link"
              class="link link-primary"
            >
              {{ tip.interpolated.text }}
            </NuxtLink>

            <ul
              v-else-if="tip.interpolated.isList"
              class="ml-4 list-outside list-disc"
              :class="{ 'space-y-2': tip.interpolated.isList }"
            >
              <!-- eslint-disable vue/no-v-html -->
              <li
                v-for="(item, i) in tip.interpolated.text.split('\n')"
                :key="`tip_${tip.title}_item_${i}`"
                v-html="item"
              />
            </ul>

            <strong
              v-else
              class="font-semibold"
            >
              {{ tip.interpolated.text }}
            </strong>
          </template>
        </i18n-t>

        <!-- eslint-disable vue/no-v-html -->
        <p
          v-else
          v-html="tip.description"
        />
      </div>
    </YscCardExpendable>
  </TransitionGroup>
</template>
